@font-face {
  font-family: "Amaline";
  src: url("./Amaline.otf") format("truetype");
}
@font-face {
  font-family: "Hurme Geometric Sans 1";
  src: url("./hur.otf") format("truetype");
}
h1 {
  font-family: "Amaline";
}
li {
  font-family: "Amaline";
}
p {
  font-family: "Hurme Geometric Sans 1";
}

html {
  scroll-behavior: smooth;
}
