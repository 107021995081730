* {
  margin: 0;
}
body {
  /* background-image: linear-gradient(
    rgba(135, 208, 229, 0.8),
    rgba(74, 121, 114, 0.1),
    rgba(135, 208, 229, 0.8)
  ); */
  /* background-image: linear-gradient(
    to right,
    rgba(157, 209, 224, 0.8) 10%,
    rgba(74, 121, 114, 0.1) 50%,
    rgba(177, 226, 239, 0.8) ,
    
  ); */

  /* background-image: linear-gradient(
      to right,
      rgba(186, 216, 225, 0.7) 0%,
      rgba(159, 192, 187, 0.2) 50%,
      rgba(172, 227, 242, 0.8) 100%
    ),
    linear-gradient(
      to bottom,
      rgba(173, 204, 212, 0.2) 0%,
      rgba(74, 121, 114, 0.1) 50%,
      rgba(179, 212, 221, 0.3) 100%
    ),
    linear-gradient(
      to right top,
      rgba(245, 252, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0.8) 100%
    ),
    linear-gradient(
      to bottom,
      rgba(0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.1) 100%
    ); */
  /* background-image: url("./BgKSA2.webp"); */

  background: radial-gradient(
      circle at 9% 1%,
      rgba(192, 210, 241, 1),
      rgba(192, 210, 241, 0) 25%
    ),
    radial-gradient(circle at 103% 21%, rgb(118 201 239), #cfd8ea75 29%),
    radial-gradient(circle at 26% 60%, rgba(65, 212, 245, 1), #cfd8ea75 50%),
    radial-gradient(circle at 82% 87%, rgba(130, 229, 255, 1), #cfd8ea75 50%),
    radial-gradient(circle at 22% 98%, rgba(245, 242, 236, 1), #ffffff75 50%);

  background-size: cover;
  background-position: center;

  background-repeat: no-repeat;
  /* backdrop-filter: saturate(90%); */
  /* backdrop-filter: blur(200px); */
  /* background-image: linear-gradient(
    rgba(#135, 208, 229, 1),
    rgba(74, 121, 114, 1)
  ); */
  /* filter: blur(10px);
  background: -webkit-linear-gradient(
    rgba(#135, 208, 229, 1),
    rgba(74, 121, 114, 1)
  );
  background: -o-linear-gradient(
    rgba(#135, 208, 229, 1),
    rgba(74, 121, 114, 1)
  );
  background: -moz-linear-gradient(
    rgba(#135, 208, 229, 1),
    rgba(74, 121, 114, 1)
  );
  background: linear-gradient(rgba(#135, 208, 229, 1), rgba(74, 121, 114, 1)); */
}
.fade {
  display: block;
  animation: fadeIn 3s infinite;
  transition: 3s ease-in-out;
}
@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

section {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media (max-width: 600px) {
  section {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.text {
  background: linear-gradient(
    to right,
    #ff4000,
    #ff4400fd,
    #ff4400fd,
    #bf87c1,
    #bf87c1,
    #9f9abb,
    #9599ff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 100;
  font-size: 50px;
  /* font-style: italic; */
}
.text-bg {
  background: linear-gradient(to right, #ef9d80ef, #998af7, #9599ff);

  color: white;
}
.weddingBg {
  background-image: url("./weddingBg.png");
  z-index: 100;
  background-size: cover;
  background-position: center;
}
@media (max-width: 600px) {
  .text {
    font-size: 30px;
  }
}
