@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@font-face {
  font-family: "TAN";
  src: url("./TAN.otf") format("truetype");
}   format('truetype');*/
@font-face {
  font-family: "Hurme Geometric Sans 1";
  src: url("./hur.otf") format("truetype");
}

@font-face {
  font-family: 'TAN';
  src: url('./fonts/TAN-MONCHERI-Regular.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TAN';
  src: url('./fonts/TAN-MONCHERI-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TAN';
  src: url('./fonts/TAN-MONCHERI-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TAN';
  src: url('./fonts/TAN-MONCHERI-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TAN';
  src: url('./fonts/TAN-MONCHERI-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-family: "TAN";
}
li {
  font-family: "TAN";
}
p {
  font-family: "Hurme Geometric Sans 1";
}

html {
  scroll-behavior: smooth;
}
